import { useEffect, useState } from 'react';

import { useForceRerender } from './useForceRerender';

export const useIsElementVisible = (target: any) => {
  const [isVisible, setIsVisible] = useState(false);

  const forceRerender = useForceRerender();

  useEffect(() => {
    forceRerender();
  }, []);

  useEffect(() => {
    if (!target) {
      return;
    }

    const observer = new IntersectionObserver(
      ([entry]) => {
        setIsVisible(entry.isIntersecting);
      },
      {
        threshold: 0.5,
      },
    );
    observer.observe(target);

    // eslint-disable-next-line consistent-return
    return () => {
      observer.unobserve(target);
    };
  }, [target]);

  return isVisible;
};
