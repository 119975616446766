import { useContext } from 'react';
import { AppConfigContext } from '../contexts/AppConfigContext';
import { AppConfigContextType } from '../contexts/AppConfigContext.types';

export const useAppConfigContext = () => {
  const contextValue = useContext(AppConfigContext);

  if (!contextValue) {
    console.log('Context has not been Provided!');
    return null as unknown as AppConfigContextType;
  }

  return contextValue;
};
