import { xmlToJson } from './common';
import { BASE_URL } from './constants';

export const getConfig = () => {
  return fetch(`${BASE_URL}/config.json`, {
    headers: {
      'Cache-Control': 'no-cache',
    },
  }).then((res) => {
    if (res.ok) {
      return res.json();
    }
    return Promise.reject(
      new Error('Something went wrong. Please try again later'),
    );
  });
};

export const getProject = (projectId: string) => {
  return fetch(`${BASE_URL}?list-type=2&prefix=${projectId}/`)
    .then((res) => {
      if (res.ok) {
        return res.text();
      }
      return Promise.reject(
        new Error('Something went wrong. Please try again later'),
      );
    })
    .then((response) => {
      const res = xmlToJson(response);
      return res?.ListBucketResult?.Contents;
    });
};

export const getPricelist = () => {
  return fetch(`${BASE_URL}/pricelist.pdf`).then((res) => {
    if (res.ok) {
      return res.blob();
    }
    return Promise.reject(
      new Error('Something went wrong. Please try again later'),
    );
  });
};
