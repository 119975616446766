import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';

import { AppConfigContext } from '../contexts/AppConfigContext';
import { ViewContext } from '../contexts/ViewContext';

import { useDimensions } from '../hooks/useDimensions';
import { useScrollToTop } from '../hooks/useScrollToTop';
import { getConfig } from '../utils/api';
import { AppConfigContextType } from '../contexts/AppConfigContext.types';

import './App.css';
import { Header } from './Header/Header';
import { Footer } from './Footer/Footer';
import { Project } from './Project/Project';
import { HomePage } from './HomePage/HomePage';
import { Preloader } from './Loader/Preloader';
import { PreloadImages } from './Loader/PreloadImages';

function App() {
  useScrollToTop();
  const [config, setConfig] = useState<AppConfigContextType | null>(null);
  const [loadingConfig, setLoadingConfig] = useState(true);
  const [loadedImages, setLoadedImages] = useState(false);
  const [cards, setCards] = useState({
    defaultCardsNum: 0,
    showedCardsNum: 0,
  });

  const isLoading = loadingConfig || !loadedImages;

  const images = useMemo(
    () => config?.projects.map((p) => p.heroImage),
    [config],
  );

  useEffect(() => {
    if (!config) {
      getConfig()
        .then((data) => setConfig(data))
        .catch((err) => console.log('err', err));
    }
    setTimeout(() => setLoadingConfig(false), 800);
  }, []);

  const headerRef = useRef<HTMLElement | null>(null);
  const { windowWidth, height: headerHeight } = useDimensions(
    headerRef,
    config,
  );

  const paddingCalc = useMemo(
    () => ({ paddingTop: `${headerHeight}px` }),
    [headerHeight],
  );

  useEffect(() => {
    if (windowWidth >= 1024) {
      setCards({ ...cards, defaultCardsNum: 3, showedCardsNum: 3 });
    } else if (windowWidth < 640) {
      setCards({ ...cards, defaultCardsNum: 1, showedCardsNum: 1 });
    } else {
      setCards({ ...cards, defaultCardsNum: 2, showedCardsNum: 2 });
    }
  }, [windowWidth]);

  const showCards = (num: number) => {
    setCards({ ...cards, showedCardsNum: num });
  };

  const resetCards = () => {
    setCards({ ...cards, showedCardsNum: cards.defaultCardsNum });
  };

  return (
    <AppConfigContext.Provider value={config}>
      <ViewContext.Provider value={cards}>
        <div className="template">
          <PreloadImages images={images} onLoaded={setLoadedImages} />
          <div className="w-full min-h-screen relative max-w-[1600px]">
            <Header
              ref={headerRef}
              className={isLoading ? 'invisible' : ''}
              handleCards={resetCards}
            />
            {!isLoading ? (
              <>
                <section style={paddingCalc} className="min-h-screen">
                  <Routes>
                    <Route
                      path="/"
                      element={
                        <HomePage
                          headerHeight={headerHeight}
                          showCards={showCards}
                        />
                      }
                    />
                    <Route path="/project/:projectId" element={<Project />} />
                    <Route path="*" element={<Navigate to="/" replace />} />
                  </Routes>
                </section>
                <Footer />
              </>
            ) : (
              <>
                <Preloader />
              </>
            )}
          </div>
        </div>
      </ViewContext.Provider>
    </AppConfigContext.Provider>
  );
}

export default App;
