import React from 'react';
import clsx from 'clsx';

import styles from './Card.module.css';

export const EmptyCard = ({ contactLink }: { contactLink: string }) => {
  return (
    <li key="yourProject" className={styles.card}>
      <div className={styles.link}>
        <div className={styles.textContainer}>
          <h2 className={styles.text}>Тут может быть проект вашей квартиры</h2>
          <a
            className={styles.contactButton}
            href={contactLink}
            target="_blank"
            rel="noreferrer"
          >
            <span
              className={clsx(
                styles.contactButtonText,
                styles.shadowPulseFilter,
              )}
            >
              Начать сотрудничество
            </span>
          </a>
        </div>
      </div>
    </li>
  );
};
