import React, { useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination, Navigation } from 'swiper/modules';
import clsx from 'clsx';

import { BASE_URL } from '../../../utils/constants';
import {
  ProjectType,
  SettingsType,
} from '../../../contexts/AppConfigContext.types';

import styles from './Card.module.css';
import { useIsElementVisible } from '../../../hooks/useIsElementVisible';
import { useDimensions } from '../../../hooks/useDimensions';

export const ProjectCard = ({
  project,
  settings,
}: {
  project: ProjectType;
  settings: SettingsType;
}) => {
  const swiperRef = useRef<any>(null);
  const cardRef = useRef<any>(null);
  const { windowWidth } = useDimensions();

  const isVisible = useIsElementVisible(cardRef?.current);
  const galleryLength = project.gallery?.length || 0;

  const renderHeroCard = () => (
    <>
      <div className={styles.imageBoxHero}>
        <img
          className={styles.image}
          src={project.heroImage}
          alt={project.name}
        />
      </div>
      <div className={styles.titleContainer}>
        <h2 className={styles.title}>{project.name}</h2>
      </div>
    </>
  );

  const goToFirstSlide = () => {
    const swiper = swiperRef?.current;
    if (!swiper || swiper.activeIndex === 0) {
      return;
    }

    swiper.slideTo(0, 800);
  };

  useEffect(() => {
    if (!isVisible && windowWidth < 768) {
      goToFirstSlide();
    }
  }, [isVisible, windowWidth]);

  return (
    <li key={project.id} className={styles.card}>
      <Link
        to={`/project/${project.id}`}
        className={styles.link}
        onMouseLeave={goToFirstSlide}
        ref={cardRef}
      >
        {galleryLength ? (
          <Swiper
            key={project.id}
            pagination
            navigation
            modules={[Pagination, Navigation]}
            onSwiper={(swiper: any) => {
              swiperRef.current = swiper;
            }}
          >
            <SwiperSlide className="overflow-hidden" key={project.id}>
              {renderHeroCard()}
            </SwiperSlide>
            {project.gallery?.map((number: string, index) => {
              if (
                settings?.galleryShowMoreToggle &&
                galleryLength - 1 === index
              ) {
                return (
                  <SwiperSlide
                    className="overflow-hidden"
                    key={`${project.id}-${number}`}
                  >
                    <div
                      className={clsx(
                        styles.imageBoxHero,
                        styles.imageBoxHeroDark,
                      )}
                    >
                      <img
                        className={styles.image}
                        src={`${BASE_URL}/${project.id}/${number}.webp`}
                        alt={project.name}
                      />
                    </div>
                    <div className={styles.titleContainer}>
                      <p className={styles.subtitle}>
                        {settings.galleryShowMoreText || 'Смотреть полностью'}
                      </p>
                    </div>
                  </SwiperSlide>
                );
              }
              return (
                <SwiperSlide
                  className="overflow-hidden"
                  key={`${project.id}-${number}`}
                >
                  <div className={styles.imageBox}>
                    <img
                      className={styles.image}
                      src={`${BASE_URL}/${project.id}/${number}.webp`}
                      alt={`${project.id}-${number}`}
                    />
                  </div>
                </SwiperSlide>
              );
            })}
          </Swiper>
        ) : (
          renderHeroCard()
        )}
      </Link>
    </li>
  );
};
