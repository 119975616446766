import React, { useContext, useMemo, useRef } from 'react';
import clsx from 'clsx';

import { useAppConfigContext } from '../../hooks/useAppConfigContext';
import { ViewContext } from '../../contexts/ViewContext';

import styles from './ProjectList.module.css';
import { ProjectCard } from './Card/ProjectCard';
import { EmptyCard } from './Card/EmptyCard';

export const ProjectList = ({
  headerHeight,
  handleShowedCards,
}: {
  headerHeight: number;
  handleShowedCards: (x: number) => void;
}) => {
  const config = useAppConfigContext();
  const { projects, settings } = config;
  const { defaultCardsNum, showedCardsNum } = useContext(ViewContext);

  const emptyItemsNum = showedCardsNum - projects.length;

  const sortedProjects = useMemo(
    () => projects.sort((a, b) => +a.num - +b.num),
    [projects],
  );

  const scollToRef = useRef<HTMLDivElement | null>(null);
  const scollToTop = useRef<HTMLDivElement>(null);

  const handleShowMore = () => {
    if (showedCardsNum < projects.length) {
      handleShowedCards(showedCardsNum + defaultCardsNum);

      scollToRef.current?.scrollIntoView({
        behavior: 'smooth',
      });
    }
  };

  const handleHide = () => {
    scollToTop.current?.scrollIntoView({ behavior: 'smooth' });
    const delay =
      (scollToTop.current?.clientHeight || 600) /
        Math.ceil(projects.length / defaultCardsNum) -
      60;
    requestAnimationFrame(() =>
      setTimeout(() => handleShowedCards(defaultCardsNum), Math.round(delay)),
    );
  };

  return (
    <nav className={styles.container}>
      <div
        className="absolute -top-[80px] bottom-0 overflow-hidden"
        ref={scollToTop}
        style={{ top: `-${headerHeight}px` }}
      />
      <ul className={styles.list}>
        {sortedProjects.map((project, index) => {
          if (index + 1 <= showedCardsNum) {
            return (
              <div className="relative" key={project.id}>
                <ProjectCard project={project} settings={settings} />
                <div
                  className="absolute bottom-0"
                  style={{ bottom: `${headerHeight}px` }}
                  ref={index + 1 === showedCardsNum ? scollToRef : undefined}
                />
              </div>
            );
          }
          return undefined;
        })}
        {emptyItemsNum >= 1 && <EmptyCard contactLink={config.telegram} />}
      </ul>
      {showedCardsNum < projects.length ? (
        <button
          type="button"
          onClick={handleShowMore}
          className={clsx(
            styles.button,
            sortedProjects.length && styles.buttonX,
          )}
        >
          <span className={styles.buttonText}>Загрузить еще</span>
          <div className={clsx(styles.arrow, styles.animation)} />
        </button>
      ) : (
        <button type="button" onClick={handleHide} className={styles.button}>
          <span className={styles.buttonText}>Свернуть</span>
          <div
            className={clsx(styles.arrow, styles.arrowUp, styles.animation)}
          />
        </button>
      )}
    </nav>
  );
};
