import React from 'react';
import clsx from 'clsx';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faTelegramPlane,
  faWhatsapp,
  faInstagram,
  faBehance,
  faYoutube,
  faVk,
} from '@fortawesome/free-brands-svg-icons';

import styles from './MessengerIcons.module.css';
import { useAppConfigContext } from '../../hooks/useAppConfigContext';
import { DEFAULT_CONFIG } from '../../utils/constants';

const ICONS = {
  telegram: faTelegramPlane,
  whatsapp: faWhatsapp,
  instagram: faInstagram,
  behance: faBehance,
  youtube: faYoutube,
  vk: faVk,
};

export const MessengerIcon = ({
  type,
  className,
}: {
  type: keyof typeof ICONS;
  className?: string;
}) => {
  const config = useAppConfigContext() || DEFAULT_CONFIG;

  return (
    <a
      href={config[type]}
      target="_blank"
      rel="noreferrer"
      className={clsx(styles.linkBox, 'glow')}
    >
      <FontAwesomeIcon
        // @ts-ignore
        icon={ICONS[type]}
        className={clsx('text-lg', className)}
      />
    </a>
  );
};
