import React, { useEffect, useState } from 'react';

export const PreloadImages = ({
  images,
  onLoaded,
}: {
  images?: string[];
  onLoaded: any;
}) => {
  const [loadedImages, setLoadedImages] = useState<string[]>([]);

  const handleLoadingImages = (url: string) =>
    setLoadedImages((prev) => [...prev, url]);

  useEffect(() => {
    if (loadedImages.length && loadedImages.length === images?.length) {
      onLoaded(true);
    }
  }, [loadedImages.length]);

  return (
    <>
      {images?.map((url) => (
        <img
          key={url}
          src={url}
          onLoad={() => handleLoadingImages(url)}
          style={{ display: 'none' }}
          alt="prelod"
        />
      ))}
    </>
  );
};
