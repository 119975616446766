import React, { Fragment } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { Dialog, Transition } from '@headlessui/react';

import styles from './Terms.module.css';

export const Terms = ({
  open,
  onClose,
}: {
  open: boolean;
  onClose: () => void;
}) => {
  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-[999]" onClose={onClose}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className={styles.transition} />
        </Transition.Child>

        <div className={styles.container}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <Dialog.Panel className={styles.dialog}>
              <div className={styles.buttonContainer}>
                <button
                  type="button"
                  className={styles.close}
                  onClick={onClose}
                >
                  <span className="sr-only">Close</span>
                  <FontAwesomeIcon
                    // @ts-ignore
                    icon={faTimes}
                  />
                </button>
              </div>

              <div className={styles.content}>
                <div className="mt-2 mb-10 space-y-4">
                  <h4 className={styles.subtitle}>1. Общие условия</h4>
                  <ol className={styles.list}>
                    <li>
                      1.1. Настоящая Политика определяет порядок обработки и
                      защиты информации о физических лицах (далее –
                      Пользователь), которая получается при оформлении
                      Пользователем заявки для получения услуг на сайте
                      chernikovdesign.ru, предоставляемых в том числе
                      посредством сайта, сервисов, служб, программ используемых
                      самозанятым Черников Павел Павлович (далее – Сайт,
                      Сервисы, Администратор сайта).
                    </li>
                    <li>
                      1.2. Целью настоящей Политики является обеспечение
                      надлежащей защиты персональных данных, которые
                      Пользователь предоставляет о себе самостоятельно при
                      использовании Сайта, Сервисов или в процессе регистрации
                      (создании учетной записи), для приобретения услуг от
                      несанкционированного доступа и разглашения.
                    </li>
                    <li>
                      1.3. Настоящая Политика применяется только к Сайту
                      chernikovdesign.ru. Сайт не контролирует и не несет
                      ответственности за сайты третьих лиц, на которые
                      Пользователь может перейти по ссылкам, доступным на Сайте.
                    </li>
                    <li>
                      1.4. Регистрируясь на Сайте и используя Сайт и Сервисы
                      Пользователь выражает свое полное согласие с условиями
                      настоящей Политики.
                    </li>
                    <li>
                      1.5. В случае несогласия Пользователя с условиями
                      настоящей Политики использование Сайта и/или каких-либо
                      Сервисов доступных при использовании Сайта должно быть
                      немедленно прекращено.
                    </li>
                  </ol>
                  <h4 className={styles.subtitle}>2. Определения</h4>
                  <ol className={styles.list}>
                    <li>
                      2.1. Персональные данные - любая информация, относящаяся к
                      прямо или косвенно определенному или определяемому
                      физическому лицу (гражданину). К такой информации, в
                      частности, можно отнести: ФИО, год, месяц, дата и место
                      рождения, адрес, сведения о семейном, социальном,
                      имущественном положении, сведения об образовании,
                      профессии, доходах, сведения о состоянии здоровья, а также
                      другую информацию. А так же данные, которые автоматически
                      передаются Пользователем с помощью, установленного на
                      устройстве Пользователя программного обеспечения, в том
                      числе IP-адрес, информация из cookie, информация о
                      браузере Пользователя (или иной программе, с помощью
                      которой осуществляется доступ к Сайту), время доступа,
                      адрес запрашиваемой страницы.
                    </li>
                    <li>
                      2.2. Обработка персональных данных - любое действие
                      (операция) или совокупность действий (операций) с
                      персональными данным, совершаемых с использованием средств
                      автоматизации или без использования таких средств. К таким
                      действиям (операциям) можно отнести: сбор, получение,
                      запись, систематизацию, накопление, хранение, уточнение
                      (обновление, изменение), извлечение, использование,
                      передачу (распространение, предоставление, доступ),
                      обезличивание, блокирование, удаление, уничтожение
                      персональных данных. Обработка персональных данных
                      осуществляется без ограничения срока.
                    </li>
                  </ol>
                  <h4 className={styles.subtitle}>
                    3. Цели сбора, обработки и хранения информации
                    предоставляемой пользователями Сайта
                  </h4>
                  <ol className={styles.list}>
                    <li>
                      <p>
                        3.1. Обработка персональных данных Пользователя
                        осуществляется в соответствии с законодательством
                        Российской Федерации, в том числе Федеральным законом «О
                        персональных данных», Федеральным законом «О рекламе» и
                        локальными нормативными документами.
                      </p>
                      <p className="mt-2">
                        Предоставляя свои персональные данные, Клиент
                        соглашается на их обработку (вплоть до отзыва
                        Пользователем своего согласия на обработку его
                        персональных данных) Администрацией сайта, в целях
                        исполнения Администратором сайта и/или его партнерами
                        обязательств перед Пользователем, предоставления услуг,
                        справочной информации, в том числе в целях продвижения
                        услуг, а также соглашается на получение сообщений
                        рекламно-информационного характера и сервисных
                        сообщений.
                      </p>
                      <p className="my-2">
                        Администратор сайта обрабатывает персональные данные
                        Пользователя в целях:
                      </p>
                      <ul>
                        <li>
                          - идентификации стороны в рамках соглашений и
                          договоров, заключаемых Пользователем с Администратором
                          сайта и/или партнером Администратора сайта в рамках
                          оказания услуг Пользователю Сайта;
                        </li>
                        <li>
                          - предоставления Пользователю услуг, доступа к Сайту,
                          Сервисам;
                        </li>
                        <li>
                          - для связи с Пользователем, отправления Пользователю
                          транзакционных писем в момент получения заявки
                          регистрации на Сайте, направлении Пользователю
                          уведомлений, запросов и т.п.;
                        </li>
                        <li>
                          - направления Пользователю сообщений рекламного и/или
                          информационного характера;
                        </li>
                        <li>
                          - проверки, исследования и анализа таких данных,
                          позволяющих поддерживать и улучшать сервисы и разделы
                          Сайта, а также разрабатывать новые сервисы и разделы
                          Сайта;
                        </li>
                        <li>
                          - проведения статистических и иных исследований на
                          основе обезличенных данных.
                        </li>
                      </ul>
                    </li>
                    <li>
                      3.2. Если Пользователь не желает получать сообщения
                      рекламного и/или информационного характера от
                      Администратора сайта, он должен направить соответствующее
                      уведомление Администратору сайта по адресу электронной
                      почты chernikovdesign@mail.ru С момента получения
                      уведомления Администратором сайта получение рассылок
                      возможно в течение 3 дней, что обусловлено особенностями
                      работы и взаимодействия информационных систем, а так же
                      условиями договоров с контрагентами, осуществляющими в
                      интересах Администратора сайта рассылки сообщений
                      рекламного и/или информационного характера.
                    </li>
                  </ol>
                  <ol className={styles.list}>
                    <h4 className={styles.subtitle}>
                      4. Условия обработки персональных данных предоставляемых
                      Пользователем и их передача третьим лицам
                    </h4>
                    <li>
                      4.1. Администратор сайта принимает все необходимые меры
                      для защиты персональных данных Пользователя от
                      неправомерного доступа, изменения, раскрытия или
                      уничтожения.
                    </li>
                    <li>
                      4.2. Администратор сайта предоставляет доступ к
                      персональным данным Пользователя только тем работникам,
                      партнерам которым эта информация необходима для
                      обеспечения функционирования Сайта, Сервисов и оказания
                      услуг, выбранных Пользователем.
                    </li>
                    <li>
                      4.3. Администратор сайта вправе использовать
                      предоставленную Пользователем информацию, в том числе
                      персональные данные, в целях обеспечения соблюдения
                      требований действующего законодательства Российской
                      Федерации (в том числе в целях предупреждения и/или
                      пресечения незаконных и/или противоправных действий
                      Пользователей). Раскрытие предоставленной Пользователем
                      информации может быть произведено лишь в соответствии с
                      действующим законодательством Российской Федерации по
                      требованию суда, правоохранительных органов, а равно в
                      иных предусмотренных законодательством Российской
                      Федерации случаях.
                    </li>
                    <li>
                      4.4. Администратор сайта не проверяет достоверность
                      информации предоставляемой Пользователем и исходит из
                      того, что Пользователь в рамках добросовестности
                      предоставляет достоверную и достаточную информацию,
                      заботится о своевременности внесения изменений в ранее
                      предоставленную информацию при появлении такой
                      необходимости, включая но не ограничиваясь изменение
                      номера телефона.
                    </li>
                    <li>
                      4.5. Не считается нарушением настоящей Политики и
                      действующего законодательства РФ предоставление
                      Администратором сайта третьим лицам данных о Пользователе
                      в обезличенной форме в целях оценки и анализа работы
                      Сайта, анализа покупательских особенностей Пользователя и
                      предоставления персональных рекомендаций, при
                      необходимости.
                    </li>
                    <li>
                      4.6. Администратор сайта вправе поручить обработку
                      персональных данных Пользователя третьим лицам, на
                      основании заключаемого с этими лицами договора.
                    </li>
                    <li>
                      4.7. Лица, осуществляющие обработку персональных данных по
                      поручению Администратора сайта, обязуются соблюдать
                      принципы и правила обработки и защиты персональных данных,
                      предусмотренные Федеральным законом No 152-ФЗ «О
                      персональных данных». Для каждого лица, установлена
                      обязанность, соблюдать конфиденциальность и обеспечивать
                      безопасность персональных данных при их обработке, а также
                      указаны требования к защите обрабатываемых персональных
                      данных.
                    </li>
                    <li>
                      4.8. Администратор сайта уничтожает либо обезличивает
                      персональные данные по достижении целей обработки, в
                      случае утраты необходимости достижения цели обработки или
                      получения от Пользователя уведомления о прекращении
                      использования персональных данных.
                    </li>
                    <li>
                      4.9. В случае перехода права администрирования Сайта
                      другому лицу. Продажи каких-либо Сервисов к указанному
                      лицу переходят все обязательства по соблюдению условий
                      настоящей Политики применительно к получаемой им
                      информации по персональным данным Пользователей.
                    </li>
                  </ol>
                  <ol className={styles.list}>
                    <h4 className={styles.subtitle}>
                      5. Условия пользования Сайтом, Сервисами
                    </h4>
                    <li>
                      5.1. Пользователь при пользовании Сайтом, подтверждает,
                      что:
                      <ul>
                        <li>
                          - обладает всеми необходимыми правами, позволяющими
                          ему осуществлять регистрацию (создание учетной записи)
                          на Сайте;
                        </li>
                        <li>
                          - указывает достоверную информацию о себе в объемах,
                          необходимых для пользования услугами Сайта,
                          обязательные для заполнения поля для дальнейшего
                          предоставления услуг помечены специальным образом, вся
                          иная информация предоставляется Пользователем по его
                          собственному усмотрению;
                        </li>
                        <li>
                          - осознает, что информация на Сайте, размещаемая
                          Пользователем о себе, может становиться доступной для
                          третьих лиц, не оговоренных в настоящей Политике, и
                          может быть скопирована и распространена ими;
                        </li>
                        <li>
                          - ознакомлен с настоящей Политикой, выражает свое
                          согласие с ней и принимает на себя указанные в ней
                          права и обязанности. Ознакомление с условиями
                          настоящей Политики и проставление галочки под ссылкой
                          на данную Политику является письменным согласием
                          Пользователя на сбор, хранение, обработку и передачу
                          третьим лицам персональных данных предоставляемых
                          Пользователем.
                        </li>
                      </ul>
                    </li>
                    <li>
                      5.2. Администратор сайта не проверяет достоверность
                      получаемой (собираемой) информации о Пользователях, за
                      исключением случаев, когда такая проверка необходима в
                      целях исполнения обязательств перед Пользователем.
                    </li>
                  </ol>
                  <ol className={styles.list}>
                    <h4 className={styles.subtitle}>
                      6. Изменение и удаление персональных данных
                    </h4>
                    <li>
                      6.1. Если Пользователь желает уточнения его персональных
                      данных, их блокирования или уничтожения в случае, если
                      персональные данные являются неполными, устаревшими,
                      неточными, незаконно полученными или не являются
                      необходимыми для заявленной цели обработки, либо в случае
                      желания клиента отозвать свое согласие на обработку
                      персональных данных или устранения неправомерных действий
                      Администратора сайта в отношении его персональных данных
                      то он должен направить официальный запрос Администратору
                      сайта по адресу электронной почты chernikovdesign@mail.ru,
                      а при отзыве своего согласия на обработку персональных
                      данных официальный запрос должен быть отправлен почтой
                      России по адресу: г. Санкт-Петербург, Спирина д 9 к 1 кв
                      35
                    </li>
                    <li>
                      6.2. Если Пользователь желает удалить свою учетную запись
                      на Сайте, Пользователь обращается к Администратору сайта
                      по адресу электронной почты chernikovdesign@mail.ru с
                      соответствующей просьбой. Данное действие не подразумевает
                      отзыв согласия Пользователя на обработку его персональных
                      данных, который согласно действующему законодательству
                      происходит в порядке, предусмотренном п. 6.1. настоящей
                      Политики.
                    </li>
                  </ol>
                  <ol className={styles.list}>
                    <h4 className={styles.subtitle}>
                      7. Изменение настоящей Политики. Применимое
                      законодательство
                    </h4>
                    <li>
                      7.1. Администратор Сайта имеет право вносить изменения в
                      настоящую Политику конфиденциальности. При внесении
                      изменений в актуальной редакции указывается дата
                      последнего обновления. Новая редакция Политики вступает в
                      силу с момента ее размещения, если иное не предусмотрено
                      новой редакцией Политики. Действующую редакцию всегда
                      можно запросить по электронной почте
                      chernikovdesign@mail.ru
                    </li>
                    <li>
                      7. 2. К настоящей Политике и отношениям между
                      Пользователем и Администратором сайта возникающим в связи
                      с применением Политики конфиденциальности, подлежит
                      применению право Российской Федерации.
                    </li>
                    <li>
                      7. 3. До обращения в суд и требованиями по спорам,
                      возникших из отношений между Пользователем и
                      Администратором сайта, обязательным является соблюдение
                      претензионного порядка. Срок рассмотрения претензии 7
                      (семь) календарных дней.
                    </li>
                  </ol>
                  <ol className={styles.list}>
                    <h4 className={styles.subtitle}>
                      8. Обратная связь. Вопросы и предложения
                    </h4>
                    <li>
                      8. 1. Все предложения или вопросы по поводу настоящей
                      Политики следует сообщать Администратору сайта по
                      электронной почте chernikovdesign@mail.ru.
                    </li>
                  </ol>
                </div>
              </div>
            </Dialog.Panel>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
};
